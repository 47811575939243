import { useQuery, gql } from '@apollo/client';
import { User } from 'types/schema.type';

const GET_PATIENTS = gql`
  query GetAuth0PatientsByClinic($clinicId: String) {
    getAuth0PatientByClinicId(clinicId: $clinicId) {
      error
      success
      patients {
        id
        email
        name
        surname
      }
    }
  }
`;

export function useAuth0Patients() {
  const { data, loading, error, refetch } = useQuery<{
    getAuth0PatientByClinicId: { patients: User[] };
  }>(GET_PATIENTS);

  return {
    patients: data?.getAuth0PatientByClinicId?.patients ?? [],
    loading,
    error,
    refetch,
  };
}
