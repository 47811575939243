import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth0Patients } from 'components/features/patients/useAuth0Patients';
import useSessionStorage from 'hooks/useSessionStorage';
import { ShortcutsText } from './shortcuts';
import { ChartSettings } from './chart-settings';
import { ButtonMenuLayout } from './button-menu.layout';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    backgroundColor: 'white',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function PatientManagementLayout() {
  const classes = useStyles();
  const { patients, loading } = useAuth0Patients();
  const [selectedPatientId, setSelectedPatientId] = useSessionStorage<string>(
    'selectedPatientId',
    '',
  );

  if (loading) {
    return null;
  }

  const handleChange = async (event) => {
    const patientId = event.target.value;

    setSelectedPatientId(patientId);

    // reload the page to apply the new patient
    window.location.reload();
  };

  const patientsMap = patients.reduce((acc, patient) => {
    acc[patient.id] = {
      fullName: patient.name && patient.surname ? `${patient.name} ${patient.surname}` : patient.name || patient.email,
    }
    
    return acc;
  }, {});

  return (
    <>
      <Button color="inherit" component={RouterLink} to="/recording-sequences">
        Recording Sequences
      </Button>
      <Button color="inherit" component={RouterLink} to="/recordings">
        Recordings
      </Button>

      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel>Active Patient</InputLabel>
        <Select value={selectedPatientId ?? ''} onChange={handleChange}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {patients?.map((patient) => (
            <MenuItem key={patient.id} value={patient.id}>
              {patientsMap[patient.id].fullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className={classes.grow} />

      <div className={classes.grow} />
      <ShortcutsText />
      <ChartSettings />
      <ButtonMenuLayout />
    </>
  );
}
