import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatSeconds } from '../utils/formatSeconds';
import { getTime } from '../utils/getTime';


const useStyles = makeStyles((theme) => ({
  root: {
    width: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
  },
  cell: {
    border: 'none',
  },
  cellSummary: {
    borderBottom: 'none',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  title: {
    paddingBottom: theme.spacing(1),
  },
}));

type HealthIndication = {
  id: string;
  type: 'Activity' | 'Symptom';
  recordedAt: number; // timestamp in milliseconds
  duration_s?: number; // in seconds, optional
  content: string;
};

type HealthIndicationTableProps = {
  healthIndications: HealthIndication[];
};

// function formatDateTime(timestamp: number): string {
//   const date = new Date(timestamp);

//   return date.toLocaleString();
// }

export function HealthIndicationTable(props: HealthIndicationTableProps) {
  const classes = useStyles();

  const { healthIndications } = props;

  const renderEmpty = () => (
    <TableBody>
      <TableRow>
        <TableCell className={classes.cell}>No health indications recorded.</TableCell>
      </TableRow>
    </TableBody>
  );

  const renderFull = () => (
    <>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Start Time</TableCell>
          <TableCell>End Time</TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Content</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {healthIndications.map((indication) => {
          const startTime = getTime(indication.recordedAt, 0);
          const endTime =
            indication.type === 'Activity' && indication.duration_s
              ? getTime(indication.recordedAt + indication.duration_s * 1000, 0)
              : '-';
          const duration =
            indication.type === 'Activity' && indication.duration_s
              ? formatSeconds(indication.duration_s)
              : '-';

          return (
            <TableRow key={indication.id}>
              <TableCell className={classes.cell}>{indication.type}</TableCell>
              <TableCell className={classes.cell}>{startTime}</TableCell>
              <TableCell className={classes.cell}>{endTime}</TableCell>
              <TableCell className={classes.cell}>{duration}</TableCell>
              <TableCell className={classes.cell}>{indication.content}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  return (
    <Container className={classes.root} component={Paper}>
      <Typography className={classes.title} variant="h6">
        Health Indications
      </Typography>
      <Table className={classes.table} size="small">
        {healthIndications.length ? renderFull() : renderEmpty()}
      </Table>
    </Container>
  );
}
